import styled from 'styled-components'

const Layout = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.gray['100']};
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Watermark = styled.h1`
  font-size: 25vw;
  text-align: center;
  position: fixed;
  width: 100vw;
  z-index: 1;
  color: ${({ theme }) => theme.palette.gray['200']};
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.07);
  top: ${`calc(50% + 64px)`};
  transform: translateY(-50%);
  font-family: ${({ theme }) => theme.typography.fontFamily.vitesseBook};
`

const Text = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily.interstate};
  font-size: ${({ theme }) => theme.typography.fontSize.xl};
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default { Layout, Watermark, Text }
