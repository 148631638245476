import Header from '@/components/UI/layouts/header'
import Styled from '@/components/UI/layouts/errors'

const Custom404 = () => {
  return (
    <>
      <Header />
      <Styled.Layout>
        <Styled.Watermark>404</Styled.Watermark>
        <Styled.Text>
          <p>Page not found.</p>
          <p>Please contact IT for assistance if this is unexpected.</p>
        </Styled.Text>
      </Styled.Layout>
    </>
  )
}

export default Custom404
